import React, { useRef, useEffect, useState } from "react";
import Helmet from "react-helmet";
import AmazeePage from "../components/layout";
import AmazeeIoMetadata from "../components/metadata";
import usePreviewData from "../utils/usePreviewData";
import { PageTitle } from "../components/title/title";
import RichTextRender from "../utils/rich-text";
import { PrismicImage, PrismicRichText } from "../utils/queries/types";
import HubspotForm from "../components/hubspot-form";
import "./campaign-form.scss";

// const ThankYouPage = ({
//   thank_you_text,
// }: {
//   thank_you_text: PrismicRichText;
// }) => {
//   return <div>{RichTextRender(thank_you_text)}</div>;
// };

const FormComponent = ({
  logo,
  back_link,
  back_link_text,
  form_id,
  portal_id,
  form_title,
  form_subtitle,
}: {
  logo?: PrismicImage;
  back_link: string;
  back_link_text: string;
  form_id: string;
  portal_id: string;
  form_title: string;
  form_subtitle: string;
}) => {
  const iframeRef = useRef(null);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    // Add event listener to listen for postMessage from the iframe
    window.addEventListener("message", handleMessage);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleMessage = (event) => {
    // Check if the message is the form submission confirmation
    if (event.data.eventName === "onFormSubmitted") {
      setSubmitted(true);
    }
  };

  return (
    <div>
      <div className="text-center">
        {logo.url && <img className="mx-auto" src={logo.url} alt={logo.alt} />}
        {/* {!submitted && ( */}
        <a className="back-button" href={back_link} title="back to link">
          {back_link_text}
        </a>
        {/* )} */}
        <h2 className="form-title">{form_title}</h2>
        {<h3 className="form-subtitle">{form_subtitle}</h3>}
      </div>

      <HubspotForm
        id={form_id}
        formId={form_id}
        portalId={portal_id}
        ref={iframeRef}
      />
    </div>
  );
};

const AmaFormPage: React.FC<{
  location: Location;
  pageContext: {
    title: string;
    meta_title: string;
    subtitle: string;
    page_meta_thumbnail?: PrismicImage;
    page_meta_description?: string;
    back_link: string;
    back_link_text: string;
    form_id: string;
    portal_id: string;
    form_title: string;
    form_subtitle: string;
    thank_you_text: PrismicRichText;
    logo: PrismicImage;
  };
}> = ({ pageContext, location }) => {
  const liveData = usePreviewData(pageContext, "prismicCampaignFormPage", true);

  const {
    title,
    meta_title,
    subtitle,
    page_meta_thumbnail,
    page_meta_description,
    back_link,
    back_link_text,
    form_id,
    portal_id,
    form_title,
    form_subtitle,
    thank_you_text,
    logo,
    canonical_url,
  } = liveData;

  const meta_title_rendered = meta_title ? meta_title : title;

  return (
    <AmazeePage
      location={location}
      id="landing-page-minimal"
      classSet="ama-form-page"
    >
      <Helmet>
        {canonical_url && <link rel="canonical" href={canonical_url} />}
      </Helmet>
      <PageTitle title={meta_title_rendered} amazeeOnly={true} />
      <AmazeeIoMetadata
        title={title}
        page_meta_description={page_meta_description}
        page_meta_thumbnail={page_meta_thumbnail}
        location={location}
      />
      <div className={form_id}>
        <div className="bg-light-blue campaign-form-page">
          <div id="signupFormWrap" className="container">
            <div className="mx-auto col-12 col-md-10 col-lg-5">
              <FormComponent
                logo={logo}
                back_link={back_link}
                back_link_text={back_link_text}
                form_id={form_id}
                portal_id={portal_id}
                form_title={form_title}
                form_subtitle={form_subtitle}
              />
            </div>
          </div>
        </div>
      </div>
    </AmazeePage>
  );
};

export default AmaFormPage;
